.loginscreen {
    height: 100h;
    border-radius: 5px;
    width: 99%;
}
.pwclogin-frm-wrapper {
    padding: 14px 12px 12px;
}
.centerele {
    margin: 0;
}
.form-group {
    position: relative;
}
.form-group label.frmlabel {
    text-transform: unset;
    font-weight: 600;
    color: #303030cf;
    letter-spacing: 1px;
    font-size: 14px !important;
    margin: 0px 0;
    padding: 0;
}
.form-group input.form-control {
    width: calc(100% - 1.2rem - 2px);
}
.frm-control {
    border: 1px solid #d2d2d2;
    font-size: 1rem;
    outline: 0;
    box-sizing: content-box;
    transition: all 0.3s;
    box-shadow: none !important;
    margin: 8px 0 18px 0 !important;
    height: 18px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    display: block;
    border-radius: 5px;
}
.frm-control:focus {
    border: 1px solid #122051 !important;
    border: 1px solid #122051 !important;
    box-shadow: 0 1px 0 0 #122051 !important;
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #007bff40;
}

.core-btn {
    padding: 7px 15px;
    border-radius: 37px;
    font-size: 11px !important;
    letter-spacing: 1px;
    transition: opacity 0.2s ease-out;
    background: #122051 !important;
    color: #fff !important;
}

.frm-header {
    font-weight: 800;
    margin-bottom: 16px;
    font-size: 23px !important;
}

.pwdbytext {
    font-weight: 600;
    color: #122051;
    margin-top: 19px;
    margin-bottom: 0;
    font-size: 13px;
}

.pwdbytext > img {
    display: inline-block;
    width: 86px;
}

.loginbtn {
    width: 100%;
    font-weight: 600;
    font-size: 14px;
}

.frm-wrap {
    border-radius: 5px;
    background-color: #fff;
    padding: 17px 12px;
    margin: auto;
    height: 405px;
    width: 303px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.main-bgimg {
    height: 100%;
    background-image: url(../../images/background/login-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 680px) {
    .main-bgimg {
        height: 100%;
        background-image: url(../../images/background/login-bg.jpg);
        background-size: auto;
        background-repeat: no-repeat;
    }
}

.validmsg {
    position: absolute;
    bottom: -3px;
    font-size: 11px;
}
